.loader-center {
  flex: 1;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: "#FF0000";
}

.loader-view {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  justify-items: center;
  width: 50px;
  height: 50px;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.center-align {
  text-align: center;
}
.rating-text {
  font-size: 30px;
  font-weight: bolder;
}

button.gm-ui-hover-effect {
  background-color: #e91e63 !important;
  opacity: 1 !important;
}
.gm-ui-hover-effect img {
  -webkit-filter: brightness(100000%) invert(10000%) contrast(10000%);
  -moz-filter: brightness(100000%) invert(10000%) contrast(10000%);
  -ms-filter: brightness(100000%) invert(10000%) contrast(10000%);
  -o-filter: brightness(100000%) invert(10000%) contrast(10000%);
  filter: brightness(100000%) invert(10000%) contrast(10000%);
}

.gm-style .gm-style-iw {
  font-size: 14px;
  padding: 15px;
}