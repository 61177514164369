.profile-label {
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 12px;
}

.postion-relative {
  position: relative !important;
}

.primary-color {
  color: #e91e63 !important;
  margin-left: 5px;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.profile-header-label {
  font-size: 12px;
  color: #e91e63;
  font-weight: 600;
  padding-left: 12px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.w-100 {
  width: 100% !important;
}

.genere-button {
  border: 2px solid #242b33;
  margin: 0;
  padding: 10px;
  text-align: center;
  color: #242b33;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-size: 15px;
  position: relative;
}

.genere-button:focus,
.genere-button:active,
.genere-button.active {
  background: #242b33;
  color: #fff;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.font-w600 {
  font-weight: 600 !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-14 {
  font-size: 14px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.text-align-center {
  text-align: center !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}
