@import "bootstrap.scss";
@import "app.scss";
@import "app-rtl.scss";
@import "custom.scss";
@import "customStyle.scss";

.form-control-select {
  background-color: #f6f9ff !important;
  border: 1px solid #f6f9ff !important;
  height: auto !important;
  box-shadow: none !important;
  padding: 15px 15px 15px 30px !important;
  border-radius: 0 !important;
  font-size: 14px;
}
.form-control-select:focus {
  border-radius: 0 !important;
  border-color: #e24f36 !important;
}
.genere-btn {
  border: 2px solid #242b33;
  margin: 0;
  padding: 10px 50px 10px 0px;
  text-align: center;
  color: #242b33;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-size: 15px;
  position: relative;
}
/* .genere-btn:hover, */
.genere-btn:focus,
.genere-btn:active,
.genere-btn.active {
  background: #242b33;
  color: #fff;
}
.genere-btn:hover .cross {
  display: block;
}
.cross {
  position: absolute;
  top: 7px;
  right: 20px;
  background: #e91e63;
  border: 1px solid #fff;
  border-radius: 50%;
  color: #fff;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 600;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  /* display: none; */
}
.dialog-form-new {
  margin: auto;
}
.dialog-form-new .dialog-heading {
  color: #ffffff;
  /* margin-bottom: 20px; */
  font-size: 18px;
  text-align: left;
  margin: 18px 20px;
  font-weight: 500;
}
.dialog-form-new .dialog-body {
  text-align: left;
}
.dialog-form-new .dialog-header {
  width: 100%;
  margin: 0;
  padding-bottom: 10px;
}
.dialog-form-new .logintxt {
  justify-content: left !important;
}
.dialog-form-new .social-button {
  width: 100%;
  margin-left: 0;
  padding: 0;
  font-size: 12px;
  background-color: transparent;
  border: 0;
}
.dialog-form-new .social-button img {
  max-width: 100%;
}
.dialog-form-new .social-button-dark {
  border-radius: 0;
  padding: 0;
  font-size: 0;
  width: 100%;
  margin-bottom: 10px;
  margin-left: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}
.dialog-form-new .social-button-dark img {
  max-width: 100%;
}
.dialog-form-new .login-form-textfield div:before,
.dialog-form-new .login-form-textfield div:after {
  display: none;
}
.dialog-form-new .login-form-textfield input {
  height: auto;
  position: relative;
  width: 100%;
  margin-top: 10px;
  padding: 15px 15px 15px 30px;
  font-family: inherit;
  font-size: 14px;
  line-height: 18px;
  font-weight: inherit;
  color: #7e8890;
  background-color: transparent;
  border: 1px solid #e9ebec;
  outline: 0;
  -webkit-appearance: none;
  box-sizing: border-box;
  cursor: auto;
}
.dialog-form-new .login-form-textfield input:focus {
  border-color: #e24f36;
}
.dialog-form-new .login-form-textfield p {
  font-size: 12px !important;
  margin-top: 0px !important;
}
.dialog-form-new .login-form-textinput div:before,
.dialog-form-new .login-form-textinput div:after {
  display: none;
}
.dialog-form-new .login-form-textinput input {
  height: auto;
  position: relative;
  width: 100%;
  margin-top: 10px;
  padding: 15px 15px 15px 30px;
  font-family: inherit;
  font-size: 14px;
  line-height: 18px;
  font-weight: inherit;
  color: black;
  background-color: #f6f9ff;
  border: 1px solid #f6f9ff;
  outline: 0;
  -webkit-appearance: none;
  box-sizing: border-box;
  cursor: auto;
}
.dialog-form-new .login-form-textinput input:focus {
  border-color: #e24f36;
}
.dialog-form-new .login-form-textinput textarea {
  height: auto;
  width: 100%;
  margin-top: 10px;
  padding: 15px 15px 15px 30px;
  font-family: inherit;
  font-size: 14px;
  line-height: 18px;
  font-weight: inherit;
  color: black;
  background-color: #f6f9ff;
  border: 1px solid #f6f9ff;
  outline: 0;
  -webkit-appearance: none;
  box-sizing: border-box;
  cursor: auto;
}
.dialog-form-new .login-form-textinput textarea:focus {
  border-color: #e24f36;
}
.dialog-form-new .login-form-textinput button {
  height: auto;
  position: relative;
  width: 100%;
  margin-top: 10px;
  padding: 15px 15px 15px 30px;
  font-family: inherit;
  font-size: 14px;
  line-height: 18px;
  font-weight: inherit;
  color: #000000;
  background-color: #f6f9ff;
  border: 1px solid #f6f9ff;
  outline: 0;
  -webkit-appearance: none;
  box-sizing: border-box;
  cursor: auto;
}
.dialog-form-new .login-form-textinput button:focus {
  border-color: #e24f36;
}
.dialog-form-new .login-form-textinput p {
  font-size: 12px !important;
  margin-top: 0px !important;
}
.dialog-form-new .log-btn button {
  max-width: 100%;
  border-radius: 50px;
  padding: 8px 35px;
  font-size: 16px;
  box-shadow: none;
  font-weight: 500;
}
.selectCategories {
  width: 100%;
}
.selectSubCategories {
  width: 100%;
}
.providerFrom {
  display: flex;
}
.providerInputs {
  label + .MuiInput-formControl {
    width: 95%;
  }
}
.adminComment {
  label + .MuiInput-formControl {
    width: 98%;
  }
}
.profile-label {
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 30px;
}

.postion-relative {
  position: relative !important;
}
.react-tel-input .special-label {
  display: none;
  position: absolute;
  z-index: 1;
  font-size: 13px;
  left: 46px;
  top: -8px;
  background: #fff;
  padding: 0 2px;
  white-space: nowrap;
}
.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  line-height: 25px;
  height: 35px;
  width: 300px;
  outline: none;
}
.dialog-form-new2 {
  margin: auto;
  margin-bottom: 30px;
}
.dialog-form-new2 .modal-list h4 {
  font-weight: 600;
  margin-top: 0;
}
.dialog-form-new {
  margin: auto;
}
.dialog-form-new .dialog-heading {
  color: #ffffff;
  /* margin-bottom: 20px; */
  font-size: 18px;
  text-align: left;
  margin: 18px 20px;
  font-weight: 500;
}
.dialog-form-new .dialog-body {
  text-align: left;
}
.dialog-form-new .dialog-header {
  width: 100%;
  margin: 0;
  padding-bottom: 10px;
}
.rdtPicker {
  display: none;
  position: absolute;
  width: 260px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  border-radius: 0.125rem;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  min-width: 160px;
}
.rdtPicker:before {
  display: inline-block;
  position: absolute;
  width: 0;
  height: 0;
  vertical-align: middle;
  content: "";
  top: -5px;
  left: 10px;
  right: auto;
  color: #ffffff;
  border-bottom: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-left: 0.4em solid transparent;
}
.rdtPicker:after {
  border-bottom: 0.4em solid #ffffff;
  border-right: 0.4em solid transparent;
  border-left: 0.4em solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
  top: -5px;
  left: 10px;
}

.rdtPicker {
  display: block;
  top: 40px;
}

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
  padding: 5px;
  border-radius: 4px;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
  border-color: #fff !important;
  border-collapse: collapse;
}

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  padding: 1px;
}

.rdtPicker td {
  cursor: pointer;
}

.rdtDay {
  height: 30px;
  line-height: 33px;
  width: 30px;
  text-align: center;
  padding: 0px;
  border-radius: 50%;
}
.rdtDay.rdtToday.rdtActive,
.rdtDay.rdtActive,
.rdtDay.rdtActive:hover {
  background-color: #9c27b0 !important;
  color: #fff;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(156, 39, 176, 0.6);
}

.rdtDays tr .dow {
  border-bottom: 1px solid #e3e3e3;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: inherit;
  padding-bottom: 5px;
  padding-top: 10px;
}

.rdtDays tr .rdtOld,
.rdtDays tr .rdtNew {
  color: #bdbdbd;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}

.rdtPicker td.rdtToday {
  position: relative;
}

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
}

.rdtPicker th.rdtSwitch {
  width: 50px;
  padding: 5px;
  border-radius: 4px;
}

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
  border-radius: 50%;
  line-height: 33px;
}

.rdtPicker .dow,
.rdtPicker th.rdtSwitch,
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev,
.rdtPicker .rdtTimeToggle {
  color: #9c27b0;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker thead tr:first-child th {
  cursor: pointer;
}

.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}

.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
  width: 40px;
  font-weight: inherit;
  margin: 3px;
  border-radius: 50%;
}

.rdtCounters .rdtCounterSeparator {
  width: 0;
  border: 1px solid transparent;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}
.rdtCounter .rdtCount {
  padding: 7px;
  height: 40px;
  border: 1px solid transparent;
}

.rdtCounters .rdtCounter:last-child .rdtCount {
  color: #9c27b0;
  border-radius: 50%;
  border: 1px solid #9c27b0;
}

.rdtCounterSeparator {
  padding: 7px;
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  line-height: 40px;
  cursor: pointer;
  display: block;
  border-radius: 50%;
  color: #eee;
  -webkit-transition: all 60ms ease-in;
  -moz-transition: all 60ms ease-in;
  -o-transition: all 60ms ease-in;
  -ms-transition: all 60ms ease-in;
  transition: all 60ms ease-in;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.rdtCounter .rdtBtn:hover {
  background: #eee;
  color: #797979;
}

.rdtCounter .rdtCount {
  font-size: inherit;
  line-height: 25px;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: inherit;
  margin-top: 37px;
}

.rdtMonths,
.rdtYears {
  padding-bottom: 10px;
}
.rdtMonths .rdtMonth,
.rdtMonths .rdtYear,
.rdtYears .rdtMonth,
.rdtYears .rdtYear {
  display: inline-block;
  width: 56px;
  height: 56px;
  line-height: 56px;
  margin: 3px 3px;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
}
.rdtMonths .rdtMonth.rdtActive,
.rdtMonths .rdtYear.rdtActive,
.rdtYears .rdtMonth.rdtActive,
.rdtYears .rdtYear.rdtActive {
  background-color: #9c27b0 !important;
  color: #fff;
}
.serviceUpdateBtn {
  font-size: smaller !important;
  margin: 0px 14px 0 0px !important;
}
.serviceDeleteBtn {
  font-size: smaller !important;
  margin: 0px 14px 0 0px !important;
}
.serviceUpdate {
  width: 100%;
}
.serviceStatus {
  margin-top: 13px !important;
}
.cardBorder {
  border: 4px solid #efefef;
}
.serviceBtn {
  background-color: #e91e63 !important;
  color: #ffffff !important;
}
.addServicesBtn {
  top: 70px;
  left: 40px;
}
.addNewBtn {
  width: 8%;
}
.addNewAddressBtn {
  border: 1px solid #e91e63 !important;
  width: 100%;
}
.noDataFound {
  font-size: large !important;
}
.serviceHeader {
  top: 50px;
}
